import * as React from "react"
import PropTypes from "prop-types"
import {Container,Navbar ,Nav ,Image} from 'react-bootstrap'
import { Link } from "gatsby"
import Logo1 from '../images/logo1.jpg'

const Header = ({ siteTitle }) => (
              <header className="bg-light">
                  {/*siteTitle */}
                  <Container className="navbar-header">
                  <Navbar expand="lg bg-light fixed-top" >
                    <Navbar.Brand  id="logo" href="/"><Image src={Logo1} /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarResponsive" />
                    <Navbar.Collapse id="navbarResponsive">
                      <Nav as="ul" className="ml-auto">
                        <Nav.Item as="li">
                          <Link to="/" className="nav-link" activeClassName="active">STARTSITE</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link to="/uberuns" className="nav-link" activeClassName="active">ÜBER UNS</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link to="/angebote" className="nav-link" activeClassName="active">ANGEBOT</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link to="/fotogalerie" className="nav-link" activeClassName="active">FOTOGALERIE</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link to="/kontakt" className="nav-link" activeClassName="active">KONTAKT</Link>
                        </Nav.Item>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  </Container>
              </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
